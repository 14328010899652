import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  orderMethod: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1.6rem 0',
  },
  departmentsMenuContainer: {
    position: 'relative',
    listStyle: 'none',
    display: 'block',
    width: 'auto',
    overflowX: 'hidden',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    borderBottom: `0.1rem solid ${theme.colors.bgLight}`,
    borderTop: `0.1rem solid ${theme.colors.bgLight}`,
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4rem',
      background: `linear-gradient(90deg, ${hexToRgba(theme.colors.background, 0)} 0%, ${hexToRgba(theme.colors.background, 1)} 100%)`,
      zIndex: '10',
      pointerEvents: 'none',
    },
  },
  departmentsMenu: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  departmentsMenuItem: {
    marginTop: '0.2rem',
    padding: '0 1.2rem',
    margin: 0,
  },
  departmentsMenuItemActive: {
    // borderBottom: `0.1rem solid ${theme.colors.text}`,
  },
  departmentsMenuItemInner: {
    padding: '1.2rem 0',
  },
  departmentsMenuItemInnerActive: {
    borderBottom: `0.2rem solid ${theme.colors.text}`,
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '1.6rem',
    padding: '2.4rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '2.4rem 0',
    },
  },
  item: {
    position: 'relative',
    margin: 0,
    borderRadius: '0.8rem',
    overflow: 'hidden',
    width: 'var(--pages-categories-item-width)',
  },
  itemLinkWithName: {
    '&::after': {
      content: 'attr(title)',
      color: theme.colors.text,
      textAlign: 'center',
      lineHeight: '1.2em',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '1em',
      backgroundColor: hexToRgba(theme.colors.background, 0.5),
    },
  },
  itemImage: {
    display: 'block',
    width: '100%',
    margin: 0,
    objectFit: 'cover',
    aspectRatio: 'var(--pages-categories-item-thumbnail-aspect-ratio)',
  },
  itemImagePlaceholder: {
    objectFit: 'contain',
  },
}))

export default useStyles
