import _ from 'lodash'
import URI from 'urijs'
import React, {
  // useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
// import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useCategories,
  useDepartments,
} from 'react-omnitech-api'
import CategoriesView from './categories-view'
// import getCategories from '../../helpers/get-categories-list'
import { useThemeConfig } from '../../hook/use-theme-config'
import { useAlert } from '../../hook/use-alert'
import useOrderMethod from '../../hook/use-order-method'
import useSku from '../../hook/use-sku'
import { useLocation } from '../../hook/use-location'
import getTopLevelCategoryCode from '../../helpers/get-top-level-category-code'

const CategoriesController = (props) => {
  const {
    parentCode,
  } = props
  const menuFilterIntervalTimer = useRef(null)
  const deptmentsTimer = useRef(null)
  const categoriesTimer = useRef(null)
  const menuCodeFilterIntervalTimer = useRef(null)
  const [menuFilterDependency, setMenuFilterDependency] = useState('')
  const [menuCodeFilterDependency, setMenuCodeFilterDependency] = useState('')
  const [departments, setDepartments] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState()
  const [loading, setLoading] = useState(true)
  // const { t } = useTranslation()
  const alert = useAlert()
  const { getContentGroup } = useThemeConfig()
  // const seoTitle = t('screens.categories.seo.title', '')
  const { fetchCategoriesAll } = useCategories()
  const { fetchDepartments } = useDepartments()
  const { orderMethod, store } = useOrderMethod()
  const {
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()
  const { location } = useLocation()

  const commerceChannelFromUrl = useMemo(() => {
    const url = new URI(_.get(location, 'href'))
    const search = url.search(true)
    return _.get(search, 'cc')
  }, [location])
  const commerceChannelFromOrderMethod = useMemo(() => (
    _.get(orderMethod, 'commerceChannel')
  ), [orderMethod])
  const showDineInOrderPlaced = useMemo(() => (
    _.get(orderMethod, 'code') === 'dineIn'
  ), [orderMethod])
  const topCategoryCode = useMemo(() => getTopLevelCategoryCode({
    topLevelCategory: _.get(orderMethod, 'topLevelCategory'),
    storeCode: _.get(store, 'code', ''),
  }), [orderMethod, store])
  const parentCodeEq = useMemo(() => (
    parentCode || topCategoryCode
  ), [parentCode, topCategoryCode])

  const bannerContentGroupProps = useMemo(() => (
    getContentGroup('config.pages.categories.banner')
  ), [getContentGroup])

  const updateMenuFilterDependency = async () => {
    const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
    const dependency = _.join(
      _.sortedUniq(
        _.flatten(
          _.values(menuFiltersParam),
        ),
      ),
      ',',
    )
    setMenuFilterDependency(dependency)
  }

  const updateMenuCodeFilterDependency = async () => {
    try {
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const dependency = _.join(
        _.sortedUniq(
          _.flatten(
            _.values(menuCodeFiltersParam),
          ),
        ),
        ',',
      )
      setMenuCodeFilterDependency(dependency)
    } catch (error) {
      // fail silently
    }
  }

  /**
   * fetchCategoriesApi
   * get product data from API
   */
  const fetchCategoriesApi = async () => {
    cancelRequest.cancelAll(['fetchCategories'])
    setLoading(true)
    try {
      // api call option
      const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const option = _.omitBy({
        // id,
        includes: [
          'meta',
          // 'parent',
          'children',
        ].join(','),
        ...(
          _.isEmpty(parentCodeEq)
            ? { parentNull: true }
            : { parentCodeEq }
        ),
        // parentCodeEq: 'food_menu_single',
        // departmentCodeEq: 'SME',
        skuActive: true,
        'skus|product_type_eq': 'product',
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
        arrayFormat: 'brackets',
      }, _.isNil)
      // call api
      const { categories: data } = await fetchCategoriesAll(option)
      setCategories(data)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      // if (generalError.code === 404) {
      //   recordNotFound(currentPage)
      //   return
      // }
      alert.show(generalError.message)
    } finally {
      setLoading(false)
    }
  }

  /**
   * fetchDepartmentsApi
   * get product data from API
   */
  const fetchDepartmentsApi = async () => {
    cancelRequest.cancelAll(['fetchDepartments'])
    setLoading(true)
    try {
      // api call option
      const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const option = _.omitBy({
        includes: [
          'category.meta',
          'categories',
        ].join(','),
        skuActive: true,
        'skus|product_type_eq': 'product',
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
        arrayFormat: 'brackets',
        pageSize: 999,
      }, _.isNil)
      // call api
      const { departments: data } = await fetchDepartments(option)
      setDepartments(data)
      setSelectedDepartment(_.get(_.first(data), 'code'))
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    } finally {
      setLoading(false)
    }
  }

  const onDepartmentClick = (code) => {
    setSelectedDepartment(code)
  }

  useEffect(() => {
    updateMenuFilterDependency()
    updateMenuCodeFilterDependency()
    menuFilterIntervalTimer.current = setInterval(() => {
      updateMenuFilterDependency()
    }, 1000 * 10) // update menu filter dependency every 10 seconds
    menuCodeFilterIntervalTimer.current = setInterval(() => {
      updateMenuCodeFilterDependency()
    }, 1000 * 60 * 10) // update menu code filter dependency every 10 minutes

    return () => {
      clearInterval(menuFilterIntervalTimer.current)
      clearInterval(menuCodeFilterIntervalTimer.current)
      cancelRequest.cancelAll(['fetchCategories'])
    }
  }, [])

  useEffect(() => {
    // Wait for commerceChannel change
    if (_.isEqual(
      commerceChannelFromUrl,
      commerceChannelFromOrderMethod,
    ) || _.isEmpty(commerceChannelFromUrl)) {
      clearTimeout(deptmentsTimer.current)
      clearTimeout(categoriesTimer.current)
      setDepartments([])
      setCategories([])
      if (_.isEmpty(parentCodeEq)) {
        deptmentsTimer.current = setTimeout(fetchDepartmentsApi, 500)
      } else {
        categoriesTimer.current = setTimeout(fetchCategoriesApi, 500)
      }
    }
  }, [
    menuFilterDependency,
    menuCodeFilterDependency,
    parentCodeEq,
    commerceChannelFromUrl,
    commerceChannelFromOrderMethod,
  ])

  useEffect(() => {
    const selectedDept = _.find(departments, { code: selectedDepartment })
    setCategories(
      _.get(selectedDept, 'categories', []),
    )
  }, [selectedDepartment, departments])

  const viewProps = {
    // pageReady,
    // seoTitle,
    loading,
    categories,
    departments,
    bannerContentGroupProps,
    onDepartmentClick,
    selectedDepartment,
    showDineInOrderPlaced,
  }

  return (
    <CategoriesView {...viewProps} />
  )
}

export default CategoriesController
